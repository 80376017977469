import React, { useEffect, useRef, useState } from 'react'
import ServiceCarousel from './ServiceCarousel'
import FaqsAccordion from './FaqsAccordion'
import { Link } from 'react-router-dom'

export default function Home() {
    const bannerRef = useRef(null);
    const navbarRef = useRef(null)

    const [isNavbarSticky, setIsNavbarSticky] = useState(false);

    const closeNav = () => {
        let a = document.getElementById('navButton')
        a.classList.add('collapsed')
        let b = document.getElementById('navbarSupportedContent')
        b.classList.remove('show')
    };

    const Service = [{
        color: "danger",
        title: "Strategic Predictions",
        content: "Analyze trends, study patterns, and make strategic predictions to stay ahead of the game. With the right insights, you can turn every trade into a winning opportunity."
    },
    {
        color: "primary",
        title: "Risk Management",
        content: "Mitigate risks and maximize rewards by adopting smart risk management techniques. Set limits and make sure your trades are balanced to make sure you keep making money."
    },
    {
        color: "success",
        title: "Continuous Learning",
        content: "Stay updated with the latest market trends, gaming strategies, and color trading techniques. To do well in the fast-paced world of color trading, you need to be willing to keep learning and change."
    }]
    const faqs = [{
        key: "one",
        title: "How can I register for the Wingo Game?",
        content: "To register for Wingo Game, simply visit our website and follow the easy sign-up process. Provide your details, create an account, and you'll be ready to dive into the thrilling world of Wingo in no time."
    },
    {
        key: "two",
        title: "Is Wingo Game available on mobile devices?",
        content: "Yes, Wingo Game is available on both desktop and mobile devices. You can download our app for iOS and Android platforms to enjoy seamless gaming experiences anytime, anywhere."
    },
    {
        key: "three",
        title: "How can I earn real money with Wingo Game?",
        content: "You can earn real money with Wingo Game by participating in games, tournaments, and challenges. Put your gaming skills to the test, make strategic predictions, and compete against players from around the world to win exciting cash prizes."
    },
    {
        key: "four",
        title: "What makes Wingo Game different from other fantasy games?",
        content: "Wingo Game stands out with its innovative one-minute game format and color trading feature, offering quick bursts of excitement and opportunities to maximize your earnings. Additionally, our vibrant community and regular updates ensure that your gaming experience is always fresh and engaging."
    }]
    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                setIsNavbarSticky(!entry.isIntersecting);
            });
        };

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };

        const bannerObserver = new IntersectionObserver(handleIntersection, observerOptions);

        if (bannerRef.current) {
            bannerObserver.observe(bannerRef.current);
        }

        return () => {
            if (bannerRef.current) {
                bannerObserver.unobserve(bannerRef.current);
            }
        };
    }, []);
    const [damanLink, setDamanLink] = useState('https://damanclub.net/#/register?invitationCode=163647009341')
    const [tirangaLink, setTirangaLink] = useState('https://tirangaclub.top/#/register?invitationCode=166731096422')
    const [diuwinLink, setDiuwinLink] = useState('https://diuapk.com/#/register?invitationCode=647779610448')
    const [telegramLink, setTelegramLink] = useState('https://t.me/kesha_daman')
    const [diuwinPredictionLink, setDiuwinPredictionLink] = useState('https://t.me/diuwinwebsite')
    const [damanPredictionLink, setDamanPredictionLink] = useState('https://t.me/damanclubwebsite')
    const [openState, setOpenState] = useState(false)

    const fetchIp = async () => {
        try {
            const response = await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=e803fa5496344b81b785fb08c5c5bb78');
            const data = await response.json();
            
            if (data.country_code2 === 'IN') {
                if (data.city !== 'Delhi' && data.city !== 'Uttar Pradesh' ) {
                    // && data.region !== 'Uttar Pradesh' && data.region !== 'Uttarakhand'
                    // setDamanLink('https://55wingo.in/#/register?invitationCode=34655523948')
                    setDamanLink('https://in999.club/#/register?invitationCode=46776342898')
                    // setDamanLink('link')
                    // setTirangaLink('https://55wingo.in/#/register?invitationCode=34655523948')
                    setTirangaLink('https://in999.club/#/register?invitationCode=46776342898')
                    setDiuwinLink('https://in999.club/#/register?invitationCode=46776342898')
                    setDamanPredictionLink('https://t.me/lusipersureshot')
                    setDiuwinPredictionLink('https://t.me/lusipersureshot')
                    // setTirangaLink('link')
                    // setTelegramLink('https://t.me/lucifier55club')
                    // setTelegramLink('https://t.me/sureshotprediction55club')
                    setTelegramLink('https://t.me/lusipersureshot')
                    setOpenState(true)
                    const tgBtn = document.querySelector('.telegram')
                    tgBtn.classList.remove('d-none')
                }
            }
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        fetchIp()
    }, [])
    const sendStats = () => {

    }
    return (
        <>
            <div className="telegram d-none">
                <a href='https://t.me/lusipersureshot'>
                    <img src="./assets/img/telegram.png" alt="telegram" width="50px" />
                </a>
            </div>
            <div className={`dialog-box p-3 border-gray-200 ${openState == true ? '' : 'd-none'}`}>
                <div className="dialog-container">
                    <div className="text-white close-btn" onClick={() => setOpenState(!openState)}>
                        <i class="fa-solid fa-circle-xmark"></i>
                    </div>
                    <h2 className='text-white fs-4 text-center'><span className="fw-bold">Register</span> <span className='fw-bold'>Start Wining Money</span></h2>
                    <div className="text-center mt-4">
                        <a href={tirangaLink} className='btn btn-danger'>Register Now</a>
                        <br />
                        <a href={telegramLink} className='mt-3 btn btn-primary fw-bold'>Join Prediction Group &nbsp; <span className="fs-5"><i class="fa-brands fa-telegram"></i></span></a>
                    </div>

                </div>
            </div>
            <header>
                <nav className={`navbar navbar-expand-lg px-lg-5 ${isNavbarSticky ? 'shadow-sm bg-white fixed-top' : ''}`} ref={navbarRef} id="navbar">
                    <div className="container-fluid">
                        <a className="navbar-brand fw-bold" href="/">WinGo</a>
                        <button className="navbar-toggler" type="button" id="navButton" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse flex-column" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb- mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link active" onClick={closeNav} aria-current="page" href="/"> <span className="me-1"><i className="fa-solid fa-home"></i></span>Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" onClick={closeNav} aria-current="page" href="#features"> <span className="me-1"><i className="fa-solid fa-wand-magic-sparkles"></i></span>Features</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active" onClick={closeNav} aria-current="page" href="/about"><span className="me-1"><i className="fa-solid fa-user"></i></span>About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" onClick={closeNav} aria-current="page" href="#faqs"><span className="me-1"><i className="fa-regular fa-circle-question"></i></span>FAQs</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active" onClick={closeNav} aria-current="page" to="/contact"><span className="me-1"><i className="fa-solid fa-headphones"></i></span>Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <main className="my-5">
                <div className="container pb-5" ref={bannerRef}>
                    <div className="row pb-lg-5">
                        <div className="col-lg-6">
                            <h1 className="fw-bold align-middle font-orange display-5 pt-5">Wingo: The Ultimate Fantasy Game Experience!</h1>
                            <p className="font-cursive pb-2 pb-lg-5">Welcome to Wingo Game - The Fantasy Game Platform where excitement meets opportunity! Are you ready to go on an exciting trip full of fun, strategy, and real money? You don't need to look any further because the Wingo Game is the most exciting experience ever.</p>
                            <div className="d-flex align-items-center justify-content-around">
                                <div className='banner-btn text-center'>
                                    <a href={diuwinPredictionLink} className="primary-btn d-block py-2 px-4 fs-5 rounded-pill">Diuwin Prediction Group</a>
                                    <a href={damanPredictionLink} className="primary-btn d-block mt-3 py-2 px-4 fs-5 rounded-pill">Daman Prediction Group</a>
                                </div>

                                <img src="./assets/img/flyer.webp" width="150px" className="flyer" loading='lazy' alt="flyer" />
                            </div>
                        </div>
                        <div className="col-lg-6 my-auto position-relative">
                            <div className="row justify-content-center align-item-center">
                                <div className="col-lg-8">
                                    <img src="/assets/img/banner1.webp" width="100%" className='rounded' alt="banner" />
                                </div>
                            </div>
                            <img src="./assets/img/gradient.webp" className="gradient-1" loading='lazy' alt="gradient" />
                        </div>
                    </div>
                </div>
                <hr />

                <div className="container text-justify my-5">
                    <div className="row g-4">
                        <div className="col-lg-4">
                            <div className="text-center mb-3">
                                <img src="./assets/img/daman.jpg" width="300px" className=' rounded' alt="daman" />
                            </div>
                            <div className='text-center my-5'>
                                <a onClick={()=>sendStats()} href={damanLink} className="primary-btn py-2 px-4 fs-5 rounded-pill">Register On Daman</a>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <h2>Daman Game – A New Era in Online Gaming</h2>
                            <p>Daman Game has emerged as a sensation among online gamers due to its engaging design and innovative gameplay mechanics. With options such as Daman game login and Daman game register, players can effortlessly enter the arena. The game’s mobile-friendly interface – available via Daman game app or Daman games app download – ensures you never miss a moment of the action, whether you're at home or on the go</p>
                            <p>For those who love to earn rewards, Daman games earn money options are particularly appealing, blending fun with tangible benefits. The user experience is further enhanced by seamless transitions between Daman games online sessions and quick access via Daman games login portals.</p>
                            <h5>What Sets Daman Game Apart:</h5>
                            <ul className="text-sm">
                                <li className="mb-2"><strong>User Accessibility:</strong>  With easy access through Daman game login and Daman game app, players can jump right into the action.</li>
                                <li className="mb-2"><strong>Mobile Compatibility:</strong> Download the game using daman games app download and enjoy the fun on-the-go.</li>
                                <li className="mb-2"><strong>Rewards System:</strong> A robust reward mechanism is in place where players can earn money while playing.
                                </li>
                                <li className="mb-2"><strong>Registration Ease: </strong> Simple daman game register and daman games login processes ensure that new players can start playing within minutes.</li>
                                <li className="mb-2"><strong>Daman Games Login: </strong> Take your gaming experience on the go with the Daman Game app. Available for download on both Android and iOS platforms, the app brings the thrill of gaming right to your fingertips.</li>
                                <li className="mb-2"><strong>Daman Games Online: </strong>Join the online gaming community at Daman Games and immerse yourself in a world of endless possibilities. With various games and challenges to choose from, boredom is simply not an option.
                                </li>
                                <li className="mb-2"><strong>Daman Games Earn Money: </strong> Turn your passion for gaming into a lucrative venture with Daman Games. Earn real money rewards as you showcase your skills, compete with fellow gamers, and emerge victorious.</li>
                            </ul>
                            <p>Whether you’re a seasoned player or a newcomer, Daman Games online offers a dynamic platform where every prediction could lead to a winning streak!</p>
                        </div>
                    </div>
                </div>

                <div className="container text-justify my-5">
                    <div className="row g-4">
                        <div className="col-lg-8">
                            <h2>Diuwin – Precision and Strategy Combined</h2>
                            <p>Another standout offering is the Diuwin game. With a focus on precision and strategy, diuwin login options and diuwin game login interfaces have been designed to provide a smooth gaming experience. Whether you choose to diuwin download the game or use diuwin download apk for your mobile device, the platform is optimized for performance and ease of use.</p>
                            <p>One of the unique aspects of Diuwin is its diuwin colour trading feature, where players engage in predictive challenges that test their understanding of market trends and color-based predictions. If you’re new, simply diuwin register and jump into a world where every decision can lead to substantial rewards.</p>
                            <h5>Key Aspects of Diuwin:</h5>
                            <ul className='text-sm'>
                                <li><strong>Effortless Onboarding: </strong>With diuwin game login and diuwin register, players can easily join and participate.</li>
                                <li><strong>Download Options: </strong>Quick access is available through diuwin download and diuwin download apk, allowing for a smooth transition to mobile gaming.</li>
                                <li><strong>Innovative Gameplay: </strong>The unique concept of diuwin colour trading adds an element of strategy to predictions, keeping the gameplay fresh and engaging.</li>
                                <li><strong>Real-Time Results: </strong>Stay updated with instant game results that enhance the overall gaming experience.</li>
                                <li><strong>Diuwin Game Login: </strong>With a user-friendly diuwin game login process, you can start playing without any hassles.</li>
                                <li><strong>Diuwin Download: </strong>Access your favorite games by simply choosing the diuwin download or diuwin download apk option.</li>
                                <li><strong> Diuwin Register:</strong> New users can easily sign up via the diuwin register portal to begin their journey in this vibrant gaming world.
                                </li>
                                <li><strong>Innovative Gameplay: </strong> The integration of colour trading in diuwin colour trading introduces an extra layer of strategy, making each game a unique challenge.</li>
                            </ul>
                            <p>Diuwin blends the excitement of prediction with the strategic depth of colour trading, ensuring that every game is a new challenge waiting to be conquered.</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="text-center mb-3">
                                <img src="./assets/img/diuwin.jpg" width="300px" className=' rounded' alt="Diuwin game" />
                            </div>
                            <div className='text-center my-5'>
                                <a onClick={()=>sendStats()} href={diuwinLink} className="primary-btn py-2 px-4 fs-5 rounded-pill">Register On Diuwin</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container text-justify my-5">
                    <div className="row g-4">
                        <div className="col-lg-4">
                            <div className="text-center mb-3">
                                <img src="./assets/img/tiranga.jpg" width="300px" className=' rounded' alt="Tiranga Game" />
                            </div>
                            <div className='text-center my-5'>
                                <a onClick={()=>sendStats()} href={tirangaLink} className="primary-btn py-2 px-4 fs-5 rounded-pill">Register On Tiranga</a>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <h2>Tiranga Game – A Fusion of Culture and Gaming Innovation</h2>
                            <p>Tiranga Game brings a unique twist to online gaming by combining cultural elements with modern gameplay. With an intuitive user interface, the tiranga game login and tiranga game registration processes are straightforward, making it simple for players to get started. Available for both desktop and mobile users – through tiranga game download or tiranga game download apk – this game ensures accessibility and engagement across all platforms.</p>
                            <p>The standout feature of Tiranga is its tiranga game colour prediction mode, where players must use their intuition and analytical skills to predict color trends in fast-paced rounds. This feature has quickly become a fan favorite, offering a refreshing alternative to traditional gaming challenges.</p>
                            <h5>Highlights of Tiranga Game:</h5>
                            <ul className='text-sm'>
                                <li><strong>Strategic Gameplay: </strong> Engage in predictions that demand both speed and strategy. The tiranga game colour prediction feature keeps you on your toes!</li>
                                <li><strong>Accessibility: </strong>Simple tiranga game login and tiranga game registration processes make it easy for new players to join.</li>
                                <li><strong>Mobile and Desktop Integration: </strong>With options like tiranga game download and tiranga game download apk, enjoy the thrill of Tiranga Game on any device.</li>
                                <li><strong>Live Gaming: </strong>Experience dynamic gameplay with the Tiranga login game and stay connected to real-time results and updates.</li>
                                <li><strong>Tiranga Game App: </strong>Immerse yourself in the world of Tiranga with our dedicated gaming app. Experience seamless gameplay, exclusive rewards, and a vibrant community of fellow patriots.</li>
                                <li><strong>Tiranga Game Download: </strong>Get ready to embark on a thrilling gaming journey with Tiranga Game. Download the app now and let the excitement begin!</li>
                                <li><strong>Tiranga Game Register: </strong>Register your Tiranga Game account and unlock a world of fantasy gaming at your fingertips. Join the community, compete with players worldwide, and claim your rightful place among the champions.</li>
                            </ul>
                            <p>Tiranga Game is designed for players who enjoy a blend of strategy and quick decisions, ensuring that every prediction is as exciting as the last.
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="container text-justify my-5">
                    <div className="row g-4">
                        <div className="col-lg-4 px-4">
                            <div className="text-center mb-3">
                                <img src="./assets/img/daman.jpg" width="300px" className=' rounded' alt="daman" />
                            </div>
                            <div className='text-center my-5'>
                                <a onClick={()=>sendStats()} href={damanLink} className="primary-btn py-2 px-4 fs-5 rounded-pill">Register On Daman</a>
                            </div>
                            <h2>About Daman Game</h2>
                            <p>In addition to Wingo Game, we also present Daman Game – another exciting destination for gaming enthusiasts looking to explore new horizons.</p>
                            <h3>Daman Game Features</h3>
                            <ul className="text-sm"><li className="mb-2"><strong>Daman Games Login: </strong>Take your gaming experience on the go with the Daman Game app. Available for download on both Android and iOS platforms, the app brings the thrill of gaming right to your fingertips.</li><li className="mb-2"><strong>Daman Games Online: </strong>Join the online gaming community at Daman Games and immerse yourself in a world of endless possibilities. With various games and challenges to choose from, boredom is simply not an option.</li><li className="mb-2"><strong>Daman Games Earn Money: </strong>Turn your passion for gaming into a lucrative venture with Daman Games. Earn real money rewards as you showcase your skills, compete with fellow gamers, and emerge victorious.</li></ul>
                        </div>
                        <div className="col-lg-4 px-4">
                            <div className="text-center mb-3">
                                <img src="./assets/img/tiranga.jpg" width="300px" className=' rounded' alt="daman" />
                            </div>
                            <div className='my-5 text-center'>
                                <a onClick={()=>sendStats()} href={tirangaLink} className="primary-btn py-2 px-4 fs-5 rounded-pill">Register on Tiranga</a>
                            </div>
                            <h2>About Tiranga Game</h2>
                            <p>Last but not least, Tiranga Game awaits those who seek adventure, excitement, and endless possibilities.</p>
                            <h3>Tiranga Game Features</h3>
                            <ul className="text-sm"><li className="mb-2"><strong>Tiranga Game App: </strong>Immerse yourself in the world of Tiranga with our dedicated gaming app. Experience seamless gameplay, exclusive rewards, and a vibrant community of fellow patriots.</li><li className="mb-2"><strong>Tiranga Game Download: </strong>Get ready to embark on a thrilling gaming journey with Tiranga Game. Download the app now and let the excitement begin!</li><li className="mb-2"><strong>Tiranga Game Register: </strong>Register your Tiranga Game account and unlock a world of fantasy gaming at your fingertips. Join the community, compete with players worldwide, and claim your rightful place among the champions.</li></ul>
                        </div>
                    </div>
                </div> */}


                {/* Section 2 */}
                <div className="mt-4">
                    <div className="container">
                        <h2 className="fs-1 fw-600 primary-text">Why Wingo Game?</h2>
                        {/* <p>Want to get dissertation writing help right away? Our 3-step easy to order process will take just 5 minutes for you to begin your journey to achieve success!</p> */}
                    </div>
                    <div className="process-container d-flex align-items-center">
                        <div className="container">
                            <div className="row row-cols-1 row-cols-lg-3 g-0 g-lg-5">
                                <div className="col mt-0">
                                    <div className="process-card px-4 py-2 py-lg-4">
                                        <h3 className='mb-4'>1. Wingo 1 Minute Game</h3>
                                        <p className="ps-4 mb-0">Our flagship game, the Wingo 1-minute game, is designed to keep you on the edge of your seat. Predict the outcome within 60 seconds, showcase your intuition, and watch your earnings soar high!</p>
                                    </div>
                                </div>
                                <div className="col mt-0">
                                    <div className="process-card px-4 py-2 py-lg-4">
                                        <h3 className='mb-4'>2. Color Trading</h3>
                                        <p className="ps-4 mb-0">Engage in thrilling color trading activities that test your strategic prowess. With our color trading tips, you can learn how to spot trends and make trades that make you money. The game is more than just a fun activity; it's an exciting trip into the worlds of money and fun.</p>
                                    </div>
                                </div>
                                <div className="col mt-0">
                                    <div className="process-card px-4 py-2 py-lg-4">
                                        <h3 className='mb-4'>3. Play and Earn Real Money</h3>
                                        <p className="ps-4 mb-0">At Wingo Game, playing isn't just for fun – it's for real money! Challenge yourself, compete with players from around the globe, and watch your money grow with each correct trade and estimate.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Section 2 End */}

                {/* section 4 */}
                <div className="container pt-5 mt-4 pt-lg-0 mt-lg-2">
                    <div className="tab-section mb-4 pb-5 pt-lg-4">
                        <nav className="d-flex justify-content-center" data-wow-delay="0.3s">
                            <div className="nav nav-tabs align-items-baseline justify-content-center" id="nav-tab"
                                role="tablist">
                                <button className="nav-link active" id="nav-SectionOne-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-SectionOne" type="button" role="tab"
                                    aria-controls="nav-SectionOne" aria-selected="false">
                                    <h2 className='fs-5'>Join Wingo Game Today</h2>
                                </button>

                                <button className="nav-link" id="nav-SectionTwo-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-SectionTwo" type="button" role="tab"
                                    aria-controls="nav-SectionTwo" aria-selected="false">
                                    <h2 className="fs-5">Conclusion</h2>
                                </button>
                            </div>
                        </nav>

                        <div className="tab-content mt-5 wow fadeInRight" id="nav-tabContent" data-wow-delay="0.5s">
                            <div className="tab-pane fade show active" id="nav-SectionOne" role="tabpanel"
                                aria-labelledby="nav-SectionOne-tab">
                                <p>
                                    Are you ready to elevate your gaming experience to new heights? Sign up for Wingo Game today and enter a world of fantasy, planning, and making real money. With so many games to choose from, Wingo Game has something for every type of gamer. Sign up now, embrace the thrill, and let the games begin!
                                </p>
                            </div>

                            <div className="tab-pane fade" id="nav-SectionTwo" role="tabpanel"
                                aria-labelledby="nav-SectionTwo-tab">
                                <p>
                                    Wingo Game - The Fantasy Game will make you feel like you're playing a game for the first time. Every minute you spend on Wingo is an adventure because of our one-minute game format, color trading tool, and chance to win real money. There is something for everyone in the world of Wingo, from casual gamers to real pros. Join us today and let the excitement begin!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 4 End */}

                {/* Social Proofs */}
                <div className="container-fluid social-proof px-5 my-5 pt-5 pb-4">
                    <div className="container pb-5">
                        <h2 className='text-center fw-semibold fs-1 mb-5'>Our Ratings</h2>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-5 g-2 ">
                            <div className="col">
                                <div className="rating-card p-2 p-lg-4">
                                    <p className='fs-1 fw-normal text-center mb-1'>4.8/5</p>
                                    <p className='text-warning text-center'>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                    </p>
                                    <p className="text-center">5000+ Students</p>
                                    <hr />
                                    <div className="text-center">
                                        <img src="./assets/img/google.webp" width={120} className='mx-auto' loading='lazy' alt="google" />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="rating-card p-2 p-lg-4">
                                    <p className='fs-1 fw-normal text-center mb-1'>5/5</p>
                                    <p className='text-warning text-center'>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                    </p>
                                    <p className="text-center">1000+ Students</p>
                                    <hr />
                                    <div className="text-center">
                                        <img src="./assets/img/trustpilot.svg" width={120} height={50} className='mx-auto' loading='lazy' alt="trustpilot" />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="rating-card p-2 p-lg-4">
                                    <p className='fs-1 fw-normal text-center mb-1'>4.9/5</p>
                                    <p className='text-warning text-center'>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                    </p>
                                    <p className="text-center">1500+ Students</p>
                                    <hr />
                                    <div className="text-center">
                                        <img src="./assets/img/sitejabber.svg" width={140} height={50} className='py-2' loading='lazy' alt="sitejabber" />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="rating-card p-2 p-lg-4">
                                    <p className='fs-1 fw-normal text-center mb-1'>4.9/5</p>
                                    <p className='text-warning text-center'>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                    </p>
                                    <p className="text-center">500+ Students</p>
                                    <hr />
                                    <div className="text-center">
                                        <img src="./assets/img/capterra.svg" width={140} height={50} className='py-2' loading='lazy' alt="capterra" />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="rating-card p-2 p-lg-4">
                                    <p className='fs-1 fw-normal text-center mb-1'>4.7/5</p>
                                    <p className='text-warning text-center'>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                        <span><i className='fa fa-star'></i></span>
                                    </p>
                                    <p className="text-center">800+ Students</p>
                                    <hr />
                                    <div className="text-center">
                                        <img src="./assets/img/reviewcenter.svg" width={140} height={50} className='py-2' loading='lazy' alt="reviewcenter" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pb-5" id='services'>
                    <div className="container">
                        <h2 className='fs-1 fw-semibold mb-5'>Color Trading: Master the Art</h2>
                        <p>Unlock the secrets of color trading and harness its power to enhance your gaming experience. From simple strategies to advanced techniques, dive deep into the world of color trading and watch your fortunes rise.</p>
                        <div className="container-fluid ">
                            <ServiceCarousel item={Service} />
                        </div>
                    </div>
                </div>


                <div className="container" id='faqs'>
                    <h2>FAQs</h2>
                    <div className="row g-5">
                        <div className="col-lg-4 p-4">
                            <img src="./assets/img/faqs.webp" loading='lazy' alt="faqs" />
                        </div>
                        <div className="col-lg-8 my-auto pt-5">
                            <FaqsAccordion data={faqs} />
                        </div>
                    </div>
                </div>

            </main>
        </>
    )
}
